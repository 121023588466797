<template>
  <div class="w-full home">This is the Home Page.</div>
</template>

<script>
import { ref, onBeforeMount, inject } from "vue";
import { logout, getUserName } from "../firebase.js";

export default {
  inject: ["global"],
  setup() {
    const name = ref("");
    onBeforeMount(() => {
      name.value = getUserName();
    });
    return {
      name,
      logout,
      getUserName,
    };
  },
};
</script>

<style lang="scss" scoped>
.bullet-list {
  text-align: left;

  li {
    margin: 0 0 8px 0;
  }

  li:before {
    content: "✓ ";
  }
}
.hero {
  // background-image: url(~@/assets/images/sinc_logo.png);
  height: 50px;
  width: 360px;
}
.home h1 {
  color: #265aa9;
}
@media (max-width: 1024px) {
  .hero {
    background-position: -200px 0px;
  }
}
@media (max-width: 500px) {
  .hero {
    height: 300px;
    background-position: -200px 0px;
  }
}
</style>